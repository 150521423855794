<template >
  <div class="row justify-content-md-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="text-right">
          <base-dropdown>
            <base-button style="margin-right: 5px; margin-top: 5px; height:40px" slot="title" type="secondary"
              class="dropdown-toggle">
              {{ this.selectedLang }}
            </base-button>
            <a @click="changeLang('en')" class="dropdown-item" href="#">English</a>
            <a @click="changeLang('de')" class="dropdown-item" href="#">Deutsch</a>
          </base-dropdown>
        </div>
        <div class="mt-4 px-lg-5">
          <div>
            <loading :active="this.activeLoading" :isFullPage=true></loading>
          </div>
          <div class="text-center text-muted mb-4">
            <small>{{ $t('loginText') }}</small>
          </div>
          <form name="form">
            <div style="padding-left:10px; padding-right:10px" class="row justify-content-md-center">
              <div class="col-4">
                <base-input @keyup.enter.native="doHandleLogin" class="centered-input input-group-alternative mb-3"
                  placeholder="XXXX" v-model="user.codePartOne" name="codePartOne" ref="codePartOne" maxlength="4"
                  @paste="parseHac"></base-input>
              </div>
              <div class="col-4">
                <base-input @keyup.enter.native="doHandleLogin" class="centered-input input-group-alternative mb-3"
                  placeholder="XXXX" v-model="user.codePartTwo" name="codePartTwo" ref="codePartTwo"
                  maxlength="4"></base-input>
              </div>
              <div class="col-4">
                <base-input @keyup.enter.native="doHandleLogin" class="centered-input input-group-alternative mb-3"
                  placeholder="XXXX" v-model="user.codePartThree" name="codePartThree" maxlength="4"
                  v-on:keyup="handleLastHacField" ref="codePartThree"></base-input>
              </div>
            </div>
            <div style="padding-left:10px; padding-right:10px">
              <base-input @keyup.enter.native="doHandleLogin"
                class="dateField centered-input input-group-alternative mb-3" :placeholder="$t('birthdate')"
                v-model="user.birthdate" name="birthDate" ref="birthDate" maxlength="10"></base-input>
            </div>
            <small>
              <span style="white-space:pre-wrap" class="serverErr" v-if="dateMsg">
                <i style="margin-right:10px" class="fas fa-exclamation-circle"></i>
                {{ dateMsg }}
              </span>
              <span style="white-space:pre-wrap" class="serverErr" v-if="validationMsg">
                <i style="margin-right:10px" class="fas fa-exclamation-circle"></i>
                {{ validationMsg }}
              </span>
              <span style="white-space:pre-wrap" class="serverErr" v-if="serverErr">
                <i style="margin-right:10px" class="fas fa-exclamation-circle"></i>
                {{ serverErr }}
              </span>
            </small>           
          </form>          
        </div>
        <div class="text-center d-flex justify-content-center mb-4">
          <base-button type="primary" class="my-3 mx-2" @keyup.enter.native="doHandleLogin" @click="doHandleLogin">
            {{ $t('viewData') }}
          </base-button>
        </div>

        <div class="appIconsDiv">
          <a href="https://play.google.com/store/apps/details?id=com.digithurst.hdscode2"> <img class="appIcon "
              width="150px" height="57px" :src='"img/appIcons/google_" + this.selectedLang + ".png"' /> </a>
          <a href="https://apps.apple.com/us/app/healthdataspace-access-code/id1542947265"> <img class="appIcon "
              width="120px" height="46px" :src='"img/appIcons/apple_" + this.selectedLang + ".svg"' /></a>
        </div>
      </div>

      <div>
        <p class="float-left text-dark loginLinks">
          Version: {{ this.version }}
        </p>
        <a style="margin-left:5px" :href="this.privacyLink" class="float-right text-dark loginLinks" target="_blank">{{
          $t('privacyPolicy') }}</a>
        <a style="margin-left:5px" :href="this.legalLink" class="float-right text-dark loginLinks" target="_blank">{{
          $t('legalNotice') }}</a>
        <a :href="this.helpLink" class="float-right text-dark loginLinks" target="_blank">{{ $t('help') }}</a>

      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import i18n from "../i18n.js";
import accountStore from '../store/accountStore';
import studyStore from '../store/studyStore'
import reportStore from '../store/reportStore'
import { auth } from '../store/authModule';
import Vue from 'vue';
import VueSession from 'vue-session'
import axios from 'axios';
import VuejsDialog from "vuejs-dialog";

Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  cancelText: "Cancel",
  animation: "zoom",
});


Vue.use(VueSession)

export default {

  beforeCreate: function () {
    document.body.className = 'login';
  },

  name: "login",
  components: { Loading },

  data() {
    return {
      activeLoading: false,
      i18n,
      version: "",
      year: new Date().getFullYear(),
      user: {
        codePartOne: "",
        codePartTwo: "",
        codePartThree: "",
        birthdate: "",
        birthDateObj: null
      },
      hacValid: false,
      loginPressed: false,
      message: "",
      validationErrors: "",
      validationMsg: "",
      dateMsg: "",
      serverErr: "",
      displayMobile: "",
      selectedLang: i18n.t("language"),
      privacyLink: i18n.t("privacyLink"),
      legalLink: i18n.t("legalLink"),
      helpLink: i18n.t("helpLink"),
      emptyShellError: false
    };
  },
  watch: {
    "user.codePartOne": function (newVal) {
      if (newVal.length == 4) {
        this.$refs.codePartTwo.$el.childNodes[2].focus()
      }
    },

    "user.codePartTwo": function (newVal) {
      if (newVal.length == 4) {
        this.$refs.codePartThree.$el.childNodes[2].focus();
      }
    },
  },
  mounted() {
    this.loadVersion(this.clearCache);

    if (navigator.language == "en") {
      i18n.locale = "en";
      this.selectedLang = "English"
    } else {
      i18n.locale = "de"
      this.selectedLang = "Deutsch"
    }

    this.privacyLink = i18n.t("privacyLink");
    this.legalLink = i18n.t("legalLink");
    this.helpLink = i18n.t("helpLink");

    this.$session.start()
    this.$session.set('default_lang', i18n.locale)

    this.changeMedviewLang(i18n.locale);

    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      this.displayMobile = "display:none";
    } else {
      this.displayMobile = "display:block";
    }
    if (this.$route.query.hac && this.$route.query.bd) {
      this.prefillHacAndBd(this.$route.query.hac, this.$route.query.bd);
    }
    else if (this.$route.query.hac) {
      this.prefillHacFromUrl(this.$route.query.hac);
    }
    let jwtUser = JSON.parse(localStorage.getItem('user'));
    if (jwtUser && new Date().getTime() < jwtUser.expiresAt) {
      this.$router.push('/home');
    }
  },

  destroyed: function () {
    document.body.className = '';
  },

  created: function () {
    this.$nextTick(() => {
      this.$refs.codePartOne.$el.childNodes[2].focus();
    })
  },

  methods: {

    handleLastHacField(event) {
      if (event.target.value.length == 4) {
        this.$refs.birthDate.$el.childNodes[2].focus();
      }
    },

    findDateDelimiter(string) {

      var dotIndex = string.indexOf(".")
      var spaceIndex = string.indexOf(" ");
      var barIndex = string.indexOf("/");
      var minusIndex = string.indexOf("-");

      var dotIndexBol = dotIndex != -1 ? true : false;
      var spaceIndexBol = spaceIndex != -1 ? true : false;
      var barIndexBol = barIndex != -1 ? true : false;
      var minusIndexBol = minusIndex != -1 ? true : false;


      if ([dotIndexBol, spaceIndexBol, barIndexBol, minusIndexBol].filter(Boolean).length === 1) {
        if (dotIndex != -1) {
          return (".")
        } else if (spaceIndex != -1) {
          return (" ")
        } else if (minusIndex != -1) {
          return ("-")
        } else if (barIndex != -1) {
          return ("/")
        }
      } else {
        return "false"
      }


    },

    changedDate() {

      var dateString = (this.$refs["birthDate"].$el.children[0].value);
      var retValue = true;

      if (dateString == "") {
        return;
      }

      var delimiter = this.findDateDelimiter(dateString);
      this.dateMsg = "";

      if (delimiter == "false") {
        this.dateMsg = this.$t("unvalidFormat")
        retValue = false;
      }

      var splitDate = dateString.split(delimiter);

      if (splitDate.length < 3) {
        this.dateMsg = this.$t("unvalidFormat")
        retValue = false;
      } else {
        var day = dateString.split(delimiter)[0]
        var month = dateString.split(delimiter)[1]
        var year = dateString.split(delimiter)[2]

        //remove leading zeros
        day = day.replace(/^0+/, '');
        month = month.replace(/^0+/, '');
        year = year.replace(/^0+/, '');

        if (day < 1 || day > 31) {
          this.dateMsg += this.$t("unvalidDay")
          retValue = false;
        }
        if (month < 1 || month > 12) {
          this.dateMsg += this.$t("unvalidMonth")
          retValue = false;
        }
        if (year.length < 2) {
          this.dateMsg += this.$t("unvalidYear")
          retValue = false;
        }

        if (year.length < 3) {
          year = parseInt(year) + 1900;
        }

        this.user.birthDateObj = new Date(year, month - 1, day, 12);
      }

      return retValue;
    },

    changeMedviewLang(lang) {

      document.head.removeChild(this.$medviewScript)

      let script = document.createElement('script')
      script.setAttribute('src', "medview/STABLE/medview/MedView_" + lang + ".js");

      document.head.appendChild(script)
      Vue.prototype.$medviewScript = script;
    },

    loadVersion(callback) {
      fetch("./version.json")
        .then((response) => response.json())
        .then((data) => callback(data));
    },

    prefillHacAndBd(hac, bd) {
      this.user.codePartOne = hac.substring(0, 4);
      this.user.codePartTwo = hac.substring(4, 8);
      this.user.codePartThree = hac.substring(8, 12);

      let year = bd.substring(0, 4);
      let month = bd.substring(4, 6);
      let day = bd.substring(6, 8);

      day = day.replace(/^0+/, '');
      month = month.replace(/^0+/, '');
      year = year.replace(/^0+/, '');

      this.user.birthdate = day + '.' + month + '.' + year;
      this.user.birthDateObj = new Date(year, month - 1, day, 12);
      this.doHandleLogin();
    },

    prefillHacFromUrl(hac) {
      this.user.codePartOne = hac.substring(0, 4);
      this.user.codePartTwo = hac.substring(4, 8);
      this.user.codePartThree = hac.substring(8, 12);
      var me = this;
      setTimeout(function () { me.$refs.birthDate.$el.childNodes[2].focus(); }, 500);
    },

    changeLang(lang) {
      i18n.locale = lang;
      this.selectedLang = i18n.t("language");
      this.privacyLink = i18n.t("privacyLink");
      this.legalLink = i18n.t("legalLink");
      this.helpLink = i18n.t("helpLink");


      this.changeMedviewLang(lang);

      //HC-95
      this.translateErrorMsg(lang);
      this.changedDate();
      this.$session.set('default_lang', i18n.locale)
    },

    validateHac(hac, bday) {
      this.hacValid = true;
      this.validationErrors = "";

      if (hac.length < 12) {
        this.hacValid = false;
        this.validationErrors += this.$t("hacTooShort") + "\n";
      }
      if (bday == "") {
        this.hacValid = false;
        this.validationErrors += this.$t("birthdateEmpty");
      }
    },

    translateErrorMsg() {
      let part1 = this.user.codePartOne ? this.user.codePartOne : "";
      let part2 = this.user.codePartTwo ? this.user.codePartTwo : "";
      let part3 = this.user.codePartThree ? this.user.codePartThree : "";
      let hac = part1 + part2 + part3;
      hac = hac.toUpperCase();

      //only display and translate if login key was pressed
      if (this.loginPressed == true) {

        this.validateHac(hac, this.user.birthdate);
        this.validationMsg = this.validationErrors;

        console.log(this.serverErr)

        if (this.serverErr) {
          if (this.emptyShellError) {
            this.serverErr = this.$t("emptyHacShell");
          } else {
            this.serverErr = this.$t("wrongCredentials");
          }
        }
      }
    },

    parseHac() {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.split("-").join("");
      this.user.codePartOne = paste.substring(0, 4);
      this.user.codePartTwo = paste.substring(4, 8);
      this.user.codePartThree = paste.substring(8, 12);
    },

    clearCache(data) {

      this.version = data[0].version;

      if (localStorage.getItem("version") != null) {
        if (localStorage.getItem("version") != this.version) {
          caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
              caches.delete(cacheName);
            });
          });
        }
      }

      Vue.prototype.$activeDocument = null;
      Vue.prototype.$activeReport = null;
      Vue.prototype.$documentName = null;
      Vue.prototype.$reportName = null;

      localStorage.removeItem('user');
      localStorage.removeItem('hacDecrypt');
      localStorage.removeItem('currentStudyId');
      localStorage.removeItem('currentReportId');
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("expirationDateDicom");
      localStorage.removeItem("plainHac");

      accountStore.methods.clean();
      studyStore.methods.clean();
      reportStore.methods.clean();
    },

    doHandleLogin() {
      this.handleLogin(10000);
    },


    async handleLogin(iter) {

      this.loginPressed = true;
      let part1 = this.user.codePartOne ? this.user.codePartOne : "";
      let part2 = this.user.codePartTwo ? this.user.codePartTwo : "";
      let part3 = this.user.codePartThree ? this.user.codePartThree : "";
      let hac = part1 + part2 + part3;
      hac = hac.toUpperCase();
      this.validateHac(hac, this.user.birthdate);

      let dateValid = this.changedDate();

      if (dateValid == false) {
        return;
      }

      this.serverErr = "";

      if (!this.hacValid) {
        this.validationMsg = this.validationErrors;
        return;
      } else {
        this.validationMsg = "";
      }
      this.activeLoading = true;
      let self = this;

      let pbkdf2 = require('pbkdf2')
      pbkdf2.pbkdf2(hac, hac, iter, 32, 'sha256', async (err, derivedKey) => {
        if (err) {
          console.error(err);
          self.activeLoading = false;
          self.serverErr = err;
          return;
        }
        self.user.derivedKey = derivedKey.toString('hex');
        auth.actions.login(self.user).then(() => {
          localStorage.setItem("version", this.version);
          localStorage.setItem("plainHac", hac);

          accountStore.methods.fetch(hac, function (entries) {
            self.activeLoading = false;

            var firstEntry = entries[0];

            if (firstEntry.type == "report") {
              reportStore.data.activeReport = firstEntry;
            } else {
              studyStore.data.activeStudy = firstEntry;
              localStorage.setItem("activeStudy", JSON.stringify(firstEntry));
            }

            for (const entry of entries) {
              if (entry.type === "study") {
                firstEntry = entry;
                studyStore.data.activeStudy = firstEntry;
                localStorage.setItem("activeStudy", JSON.stringify(firstEntry));
                break;
              }
            }

            if (firstEntry) {
              self.$router.push('/' + firstEntry.type + "/" + firstEntry.id);
              Vue.prototype.$activeDocument = firstEntry.type;
              Vue.prototype.$documentName = firstEntry.name;
            } else {
              self.$router.push('/home');
            }
          },
            (error) => {
              self.activeLoading = false;
              if (error == "hacEmpty") {
                error = self.$t("hacEmpty")
              }
              self.serverErr = error;
            });
        },
          (error) => {

            self.activeLoading = false;

            axios.get('/v1/accounts/code/empty/' + self.user.derivedKey).then(response => {
              if (response.data.emptyShell == true) {
                this.serverErr = this.$t("emptyHacShell");
                this.emptyShellError = true;
              } else {
                this.emptyShellError = false;

                if (iter > 1001) {
                  self.handleLogin(1000);
                  return;
                }

                if (error.response && error.response.status == 401) {
                  this.serverErr = this.$t("wrongCredentials");
                }
                if (error.response && error.response.status == 403) {
                  this.serverErr = this.$t("tooManyTries") + error.response.data.wait_seconds + this.$t("seconds")
                }
              }
            }).catch(e => {
              console.log(e);
            })
          });

      });
    },
  },
};
</script>
<style>
.appIcon {
  margin: 0px;
}

.appIconsDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginLink {
  margin-right: 5px;
}

.loginLinks {
  font-size: 0.8rem;
}

.centered-input input {
  text-align: center;
  text-transform: uppercase;
}

.validateError {
  color: red;
  font-size: 10px;
}

.serverErr {
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  padding: 8px 8px;
  display: block;
  text-align: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.dateField input {
  text-transform: lowercase;
}

.datePickerDiv {
  position: relative;
  float: left;
  margin-top: -40px;
  border: 1px solid red;
}

.datePickerDiv input {
  border: none;
  background: transparent;
}

.datePickerDiv>>>input {
  border: none;
  background: transparent;
}

::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}
</style>